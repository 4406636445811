<template>
  <div class="rich-text" :class="{ 'rich-text--disabled': disabled }">
    <span class="rich-text__title">{{ title }}</span>
    <div class="rich-text__header">
      <button type="button" class="rich-text__button" @click="bold" title="Жирный текст">
        <IconComponent name="text-bold" />
      </button>
      <button type="button" class="rich-text__button" @click="italic" title="Курсив">
        <IconComponent name="text-italic" />
      </button>
      <button type="button" class="rich-text__button" @click="underline" title="Подчеркнутый текст">
        <IconComponent name="text-underline" />
      </button>
      <br />
      <button type="button" class="rich-text__button" @click="code" title="Форматирование">
        <IconComponent name="code-1" />
      </button>
      <button type="button" class="rich-text__button" @click="quote" title="Цитата">
        <IconComponent name="quote-down" />
      </button>
      <br />
      <button type="button" class="rich-text__button" @click="listUnordered" title="Ненумерованный список">
        <IconComponent name="list-unordered" />
      </button>
      <button type="button" class="rich-text__button" @click="listOrdered" title="Нумерованный список">
        <IconComponent name="list-ordered" />
      </button>
      <br />
      <button type="button" class="rich-text__button" @click="alignLeft" title="Выравнивание слева">
        <IconComponent name="textalign-left" />
      </button>
      <button type="button" class="rich-text__button" @click="alignCenter" title="Выравнивание по центру">
        <IconComponent name="textalign-center" />
      </button>
      <button type="button" class="rich-text__button" @click="alignRight" title="Выравнивание справа">
        <IconComponent name="textalign-right" />
      </button>
      <br />
      <button type="button" class="rich-text__button" @click="undo" title="Отменить действие">
        <IconComponent name="undo" />
      </button>
      <button type="button" class="rich-text__button" @click="redo" title="Вернуть отмененное действие">
        <IconComponent name="redo" />
      </button>
    </div>
    <div class="rich-text__body" ref="editor"></div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "RichTextInputComponent",
  components: { IconComponent },
  props: {
    title: String,
    value: String,
    disabled: Boolean,
  },
  data: () => ({
    editor: null,
  }),
  watch: {
    value() {
      if (this.editor) {
        this.editor.setHTML(this.value);
      }
    },
  },
  mounted() {
    require(["squire-rte"], (Squire) => {
      Squire.prototype.testPresenceInSelection = function (format, validation) {
        return validation.test(this.getPath()) | this.hasFormat(format);
      };
      this.editor = new Squire(this.$refs.editor, {
        blockTag: "P",
      });
      this.editor.addEventListener("focus", () => {
        if (this.disabled) {
          this.editor.blur();
        }
      });
      // this.editor.addEventListener("input", () => {
      // this.$emit("input", this.editor.getHTML());
      // });
      this.editor.setHTML(this.value);
    });
  },
  methods: {
    emit() {
      return this.$emit("input", this.editor.getHTML());
    },
    code() {
      this.editor.toggleCode();
    },
    alignLeft() {
      this.editor.setTextAlignment("left");
    },
    alignRight() {
      this.editor.setTextAlignment("right");
    },
    alignCenter() {
      this.editor.setTextAlignment("center");
    },
    undo() {
      this.editor.undo();
    },
    redo() {
      this.editor.redo();
    },
    bold() {
      if (this.editor.testPresenceInSelection("B", />B\b/)) {
        this.editor.removeBold();
      } else {
        this.editor.bold();
      }
    },
    italic() {
      if (this.editor.testPresenceInSelection("I", />I\b/)) {
        this.editor.removeItalic();
      } else {
        this.editor.italic();
      }
    },
    underline() {
      if (this.editor.testPresenceInSelection("U", />U\b/)) {
        this.editor.removeUnderline();
      } else {
        this.editor.underline();
      }
    },
    quote() {
      if (this.editor.testPresenceInSelection("blockquote", />blockquote\b/)) {
        this.editor.decreaseQuoteLevel();
      } else {
        this.editor.increaseQuoteLevel();
      }
    },
    listOrdered() {
      if (this.editor.testPresenceInSelection("OL", />OL\b/)) {
        this.editor.removeList();
      } else {
        this.editor.makeOrderedList();
      }
    },
    listUnordered() {
      if (this.editor.testPresenceInSelection("UL", />UL\b/)) {
        this.editor.removeList();
      } else {
        this.editor.makeUnorderedList();
      }
    },
  },
};
</script>

<style lang="stylus">
.rich-text {
  background var(--white)
  border 1px solid var(--blue-100)
  box-sizing border-box
  border-radius var(--radius)
  position relative
  cursor text
  transition var(--transition)
  display flex
  flex-direction column
  margin 0

  &--disabled {
    opacity 0.7
    cursor not-allowed
  }

  &__title {
    font-size: 0.75rem;
    line-height: 15px;
    padding: 5px 12px;
    margin-top: 0;
  }

  &__header {
    padding: 0 12px;
    display: grid;
    grid-auto-flow: column dense;
    justify-content: start;
    gap 3px
  }

  &__button {
    border none
    background none
    cursor pointer
    display inline-flex
    align-items center
    padding 4px
    background var(--blue-100)
    border-radius var(--radius)

    &:hover {
      background var(--blue-500)
    }

    .icon {
      width 14px
      height 14px

      svg path {
        fill var(--white)
      }
    }
  }

  &__body {
    min-height 100px
    outline none
    margin 6px 16px 0

    ul, ol {
      margin: inherit;
      padding: inherit;
    }

    blockquote {
      padding: 2px 5px 4px 12px;
      border-radius: 3px;
      background: var(--gray-100);
      border-left: 7px solid var(--blue-100);
    }
  }
}
</style>
