<template>
  <div class="select-list">
    <template v-if="filteredOptions.length">
      <label
        v-for="(o, i) in filteredOptions"
        :key="i"
        class="select-list__item"
        :class="{ 'select-list__item--active': isChecked(o) }"
      >
        <CheckboxComponent @input="toggle(o)" :value="isChecked(o)" v-if="multiple" :disabled="disabled">
          <span>{{ getOptionTitle(o) }}</span>
        </CheckboxComponent>
        <button type="button" @click.prevent="toggle(o)" :disabled="disabled" v-else>
          <slot :i="i" :item="o">
            {{ getOptionTitle(o) }}
          </slot>
        </button>
      </label>
    </template>
    <span v-else class="select-list__empty">Пусто</span>
  </div>
</template>

<script>
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";

export default {
  name: "SelectListComponent",
  props: {
    labelName: {
      type: [String, Array],
      default: "title",
    },
    query: {
      type: String,
      default: "",
    },
    value: [Array, Object],
    options: { type: Array, required: true },
    multiple: Boolean,
    disabled: Boolean,
  },
  computed: {
    filteredOptions() {
      return this.options.filter((o) =>
        this.getOptionTitle(o).toLowerCase().includes(this.query.toLowerCase())
      );
    },
  },
  methods: {
    isChecked(option) {
      if (this.multiple) {
        return this.value.map((s) => JSON.stringify(s)).includes(JSON.stringify(option));
      } else {
        return JSON.stringify(option) === JSON.stringify(this.value);
      }
    },
    toggle(o) {
      if (this.multiple) {
        let array = this.value;
        if (this.isChecked(o)) {
          let indexOfSelected = this.value.findIndex((v) => JSON.stringify(v) === JSON.stringify(o));
          array.splice(indexOfSelected, 1);
        } else {
          array.push(o);
        }
        this.$emit("input", array);
      } else {
        this.$emit("input", o);
      }
    },
    getOptionTitle(option) {
      if (Array.isArray(this.labelName)) {
        return option[this.labelName[0]][this.labelName[1]];
      }
      return option[this.labelName];
    },
  },
  components: { CheckboxComponent },
};
</script>

<style lang="stylus">
.select-list {
  background: var(--white);
  border-radius: var(--radius);
  display flex
  flex-direction column
  align-items start
  overflow auto
  padding 5px 0
  scrollbar-width thin
  scrollbar-color rgba(0, 0, 0, 0.3) transparent

  &::-webkit-scrollbar {
    width 8px
    height 8px
  }

  &::-webkit-scrollbar-track {
    background transparent
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color rgba(0, 0, 0, 0.3)
    border-radius 3px
    border none
  }

  &__item {
    display inline-flex
    align-items center
    justify-content stretch
    width 100%
    height 100%

    &--active& {
      button {
        background var(--blue-100)
        color var(--white)
      }
    }

    .checkbox,
    button {
      padding 5px 10px
      transition background .2s
      background none
      border none
      width 100%
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 16px;
      color: var(--dark);
      text-align left
      min-height 18px

      &:hover {
        background var(--blue-100)
        color var(--white)
      }

      &__field {
        gap 5px
        padding 5px 10px
      }
    }

    .checkbox {
      padding 0
    }
  }

  &__empty {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    color: var(--dark);
    padding 5px 10px
  }
}
</style>
