<template>
  <div class="task-view" :class="{ 'task-view--visible': $store.state.task.open }">
    <div class="task-view__header">
      <CheckboxComponent
        :value="form.is_important.value"
        @input="form.is_important.value = $event"
        v-if="$store.state.user.platrum_id"
      >
        Срочная
      </CheckboxComponent>
      <div class="task-view__header-buttons">
        <button
          type="button"
          class="btn btn--sm btn--main"
          @click="saveTask"
          v-if="!isDisabledForNotPlatrumUser"
        >
          <LoadingIndicator title="Сохранение" v-if="saving" />
          <span v-else>Сохранить</span>
        </button>
        <button
          type="button"
          class="btn btn--sm btn--red"
          v-if="canDelete && !isDisabledForNotPlatrumUser"
          @click="deleteTask"
        >
          <LoadingIndicator title="Удаление" v-if="removing" />
          <span v-else>Удалить</span>
        </button>
        <button type="button" class="task-view__close" @click="closeTask">
          <IconComponent name="close" />
        </button>
      </div>
    </div>
    <div class="task-view__section">
      <span class="task-view__section-title">Основное</span>
      <div class="task-view__section-body">
        <InputComponent
          title="Название задачи"
          v-model="form.title.value"
          :disabled="isDisabledForNotPlatrumUser"
        />
        <RichTextInputComponent
          ref="description"
          title="Описание задачи"
          v-model="form.description.value"
          :disabled="isDisabledForNotPlatrumUser"
        />
      </div>
    </div>
    <div
      class="task-view__section"
      :class="{ 'task-view__section--loading': listLoading }"
      v-if="$store.state.task.data"
    >
      <span class="task-view__section-title">Чек-лист задач</span>
      <div class="task-view__section-body">
        <div class="task-view__check-list" ref="checklist">
          <CheckboxComponent
            :data-id="v.id"
            :data-order="v.order"
            v-for="(v, i) in list"
            @input="toggleCheckListItem(i)"
            :value="!!v.closing_date"
            :key="i"
            :disabled="listLoading || isDisabledForNotPlatrumUser"
          >
            <input
              class="task-view__check-list-input"
              v-model="v.title"
              :class="{
                'task-view__check-list-input--disabled': isDisabledForNotPlatrumUser,
              }"
              :disabled="listLoading || isDisabledForNotPlatrumUser"
              @blur="editCheckListItem(i)"
              @keyup.enter="editCheckListItem(i)"
            />
            <button
              type="button"
              class="task-view__check-list-remove"
              @click="removeCheckListItem(i)"
              v-if="!isDisabledForNotPlatrumUser"
            >
              <IconComponent name="trash" />
            </button>
          </CheckboxComponent>
          <input
            placeholder="Добавить пункт"
            class="task-view__check-list-input"
            :class="{
              'task-view__check-list-input--disabled': isDisabledForNotPlatrumUser,
            }"
            @keyup.enter="addCheckListItem"
            :disabled="isDisabledForNotPlatrumUser"
            v-model="listLast"
          />
        </div>
      </div>
    </div>
    <SelectComponent
      id="owner"
      title="Постановщик"
      v-model="form.owner.value"
      :options="$store.state.users"
      label-name="name"
      :disabled="!$store.state.user.platrum_id"
    />
    <SelectComponent
      id="responsible"
      title="Исполнители"
      v-model="form.responsible.value"
      label-name="name"
      :options="$store.state.users"
      :disabled="!$store.state.user.platrum_id"
      multiple
    />
    <SelectComponent
      id="auditors"
      title="Аудиторы"
      v-model="form.auditors.value"
      label-name="name"
      :options="$store.state.users"
      :disabled="!$store.state.user.platrum_id"
      multiple
    />
    <div class="task-view__section">
      <span class="task-view__section-title">Оценка</span>
      <div class="task-view__section-body">
        <div class="task-view__points">
          <InputComponent
            title="Story points"
            v-model="form.time_plan.value"
            :disabled="!$store.state.user.platrum_id"
          />
          <InputComponent
            title="Время (ч)"
            v-model="form.time_fact.value"
            :disabled="!$store.state.user.platrum_id"
          />
        </div>
      </div>
    </div>
    <div class="task-view__section">
      <span class="task-view__section-title">Начало и конец задачи</span>
      <div class="task-view__section-body">
        <DatePicker
          v-if="mounted && $store.state.user.platrum_id"
          :columns="2"
          v-model="form.date.value"
          :first-day-of-week="2"
          mode="datetime"
          locale="ru"
          title-position="left"
          trim-weeks
          is24hr
          is-range
          is-expanded
        />
        <InputComponent title="" v-if="!$store.state.user.platrum_id" :value="dateRangeAsString" disabled />
      </div>
    </div>
    <div
      class="task-view__comments"
      :class="{ 'task-view__comments--visible': commentsVisible }"
      v-if="!listLoading"
    >
      <div class="task-view__comments-toggle" @click="toggleComments">
        <IconComponent name="arrow-up-2" />
      </div>
      <div class="task-view__comments-list" ref="commentsList">
        <div
          class="task-view__comment"
          v-for="(comment, i) in commentList"
          :key="i"
          :title="comment.creation_date | humanDate"
        >
          <img :src="comment.user.avatar | img(100, 100)" :alt="comment.user.name" />
          <div>
            <span>{{ comment.user.name }}</span>
            <div v-html="comment.text"></div>
          </div>
        </div>
      </div>
      <div class="task-view__comments-form" v-if="$store.state.user.platrum_id">
        <RichTextInputComponent ref="comment" title="Добавить комментарий" v-model="commentText" />
        <button type="button" class="btn btn--sm btn--main" @click="saveComment">
          <LoadingIndicator title="Отправка" v-if="commentsSaving" />
          <span v-else>Отправить</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import SelectComponent from "components/inputs/select/input.vue";
import dayjs from "dayjs";
import RichTextInputComponent from "components/inputs/RichTextInputComponent.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "TaskViewComponent",
  data: () => ({
    mounted: false,
    dragula: null,
    commentList: null,
    commentText: null,
    commentsVisible: false,
    commentsSaving: false,
    list: null,
    listDragula: null,
    listLoading: true,
    listLast: null,
    saving: false,
    removing: false,
    form: {
      is_important: {
        value: false,
        errors: [],
        messages: [],
      },
      title: {
        value: null,
        errors: [],
        messages: [],
      },
      description: {
        value: null,
        errors: [],
        messages: [],
      },
      owner: {
        value: null,
        errors: [],
        messages: [],
      },
      responsible: {
        value: [],
        errors: [],
        messages: [],
      },
      auditors: {
        value: [],
        errors: [],
        messages: [],
      },
      time_plan: {
        value: null,
        errors: [],
        messages: [],
      },
      time_fact: {
        value: null,
        errors: [],
        messages: [],
      },
      date: {
        value: null,
        errors: [],
        messages: [],
      },
    },
  }),
  computed: {
    dateRangeAsString() {
      if (this.form.date.value) {
        let start = this.form.date.value.start;
        let end = this.form.date.value.end;
        if (start || end) {
          start = start ? dayjs(start).format("DD.MM.YYYY HH:mm") : "";
          end = end ? dayjs(end).format("DD.MM.YYYY HH:mm") : "";
          return `${start} - ${end}`;
        }
      }
      return "";
    },
    canDelete() {
      return !!this.$store.state.task.data && this.$store.state.user.platrum_id;
    },
    isDisabledForNotPlatrumUser() {
      if (!this.$store.state.user.platrum_id) {
        if (this.$store.state.task.data) {
          return this.$store.state.task.data.status_key !== "new";
        }
      }
      return false;
    },
    data() {
      return this.$store.state.task.data;
    },
  },
  created() {
    if (this.data) {
      this.form.is_important.value = this.data.is_important;
      this.form.title.value = this.data.name;
      this.form.description.value = this.data.description;
      this.form.time_plan.value = this.data.time_plan;
      this.form.time_fact.value = this.data.time_fact;
      this.form.date.value = {
        start: this.data.start_date ? dayjs(this.data.start_date).toDate() : undefined,
        end: this.data.end_date ? dayjs(this.data.end_date).toDate() : undefined,
      };
      this.form.owner.value = this.$store.state.users.find((u) => u.user_id === this.data.owner_user_id);
      this.form.responsible.value = this.$store.state.users.filter((u) => {
        return this.data.responsible_user_ids.includes(u.user_id);
      });
      this.form.auditors.value = this.$store.state.users.filter((u) => {
        return this.data.auditors.includes(u.user_id);
      });
      this.loadSecondary();
    }
  },
  mounted() {
    require(["dragula"], (dragula) => {
      this.dragula = dragula;
      this.initCheckList();
    });
    this.mounted = true;
  },
  methods: {
    toggleComments() {
      this.commentsVisible = !this.commentsVisible;
      if (this.commentsVisible) {
        this.$nextTick(() => {
          this.$refs.commentsList.scrollTop = this.$refs.commentsList.scrollHeight;
        });
      }
    },
    loadSecondary() {
      return this.$platrum.query(this, "/tasks/api/task/data?id=" + this.data.id).then(({ data }) => {
        this.commentList = data.comments
          .map((c) => {
            c.user = this.$store.state.users.find((u) => u.user_id === c.author_id);
            return c;
          })
          .sort((a, b) => dayjs(a.creation_date).diff(b.creation_date));
        this.list = data.checklist
          .sort((a, b) => a.order - b.order)
          .map((d) => ({
            id: d.id,
            title: d.text,
            order: d.order,
            closing_date: d.closing_date,
          }));
        this.listLoading = false;
      });
    },
    saveComment() {
      if (!this.commentsSaving) {
        this.commentsSaving = true;
        this.$refs.comment.emit();
        this.$platrum
          .query(
            this,
            "/tasks/api/tasks/comment/save",
            {
              author_id: this.$store.state.user.platrum_id,
              file_ids: [],
              id: null,
              mentioned_user_ids: [],
              task_id: this.data.id,
              temporary_id: 0,
              text: this.commentText,
            },
            "post"
          )
          .then(() => {
            this.resetCache();
            let index = this.$store.state.tasks.findIndex((t) => t.id === this.data.id);
            this.$store.state.tasks[index].comment_count = this.$store.state.tasks[index].comment_count + 1;
            this.loadSecondary().then(() => {
              this.commentText = null;
              this.commentsSaving = false;
              this.$nextTick(() => {
                this.$refs.commentsList.scrollTop = this.$refs.commentsList.scrollHeight;
              });
            });
          });
      }
    },
    initCheckList() {
      this.listDragula = this.dragula([this.$refs.checklist], {
        moves: (el) => {
          return !this.listLoading && el.classList.contains("checkbox");
        },
      });
      this.listDragula.on("drop", (el, source) => {
        this.listLoading = true;
        let ids = Array.from(source.querySelectorAll(".checkbox")).map((n) => n.dataset.id);
        this.$platrum
          .query(
            this,
            `/tasks/api/tasks/checklist/order/update`,
            {
              author_id: this.$store.state.user.platrum_id,
              task_id: this.data.id,
              checklist_ids: ids,
            },
            "post"
          )
          .then(() => {
            this.listLoading = false;
          });
      });
    },
    editCheckListItem(i) {
      if (!this.listLoading) {
        this.listLoading = true;
        let item = this.list[i];
        this.$platrum
          .query(
            this,
            `/tasks/api/tasks/checklist/save`,
            {
              author_id: this.$store.state.user.platrum_id,
              id: item.id,
              task_id: this.data.id,
              text: item.title,
              order: item.order,
              closing_date: item.closing_date || undefined,
            },
            "post"
          )
          .then(() => {
            this.listLoading = false;
          });
      }
    },
    addCheckListItem() {
      let order = this.list.length;
      if (!this.listLoading) {
        this.listLoading = true;
        this.$platrum
          .query(
            this,
            `/tasks/api/tasks/checklist/save`,
            {
              task_id: this.data.id,
              text: this.listLast,
              order: order * 100,
            },
            "post"
          )
          .then(({ data }) => {
            this.list.push({
              author_id: this.$store.state.user.platrum_id,
              id: data.id,
              title: data.text,
              order: data.order,
              closing_date: data.closing_date,
            });
            this.listLast = null;
            this.updateTaskChecklistData();
            this.resetCache().then(() => {
              this.listLoading = false;
            });
          });
      }
    },
    removeCheckListItem(i) {
      if (confirm("Вы уверены что хотите УДАЛИТЬ этот элемент?") && !this.listLoading) {
        this.listLoading = true;
        let item = this.list[i];
        this.$platrum.query(this, "/tasks/api/tasks/checklist/delete?item_id=" + item.id).then(() => {
          this.list.splice(i, 1);
          this.updateTaskChecklistData();
          this.resetCache().then(() => {
            this.listLoading = false;
          });
        });
      }
    },
    toggleCheckListItem(i) {
      let item = this.list[i];
      let now = item.closing_date ? null : dayjs().format("YYYY-MM-DD HH:mm:ss");
      if (!this.listLoading) {
        this.listLoading = true;
        this.$platrum
          .query(
            this,
            `/tasks/api/tasks/checklist/save`,
            {
              author_id: this.$store.state.user.platrum_id,
              id: item.id,
              task_id: this.data.id,
              closing_date: now,
              text: item.title,
              order: item.order,
            },
            "post"
          )
          .then(() => {
            this.list[i].closing_date = now;
            this.updateTaskChecklistData();
            this.resetCache().then(() => {
              this.listLoading = false;
            });
          });
      }
    },
    updateTaskChecklistData() {
      let index = this.$store.state.tasks.findIndex((t) => t.id === this.data.id);
      this.$store.state.tasks[index].checklist_data = {
        done_count: this.list.filter((v) => !!v.closing_date).length,
        total_count: this.list.length,
      };
    },
    saveTask() {
      if (!this.saving) {
        this.$refs.description.emit();
        this.saving = true;
        let body = {};
        let fields = {};
        fields.author_id = this.$store.state.user.platrum_id;
        fields.status_key = "new";
        let panels = this.$store.state.board.panels.sort((a, b) => a.order - b.order);
        fields.board_panel_id = panels[0] ? panels[0].id : undefined;
        fields.is_important = this.form.is_important.value;
        fields.name = this.form.title.value || undefined;
        fields.description = this.form.description.value || undefined;
        fields.owner_user_id = this.form.owner.value ? this.form.owner.value.user_id : "";
        fields.responsible_user_ids = this.form.responsible.value.map((r) => r.user_id);
        fields.auditors = this.form.auditors.value.map((a) => a.user_id);
        fields.time_plan = this.form.time_plan.value || undefined;
        fields.time_fact = this.form.time_fact.value || undefined;
        if (this.form.date.value) {
          fields.start_date = this.form.date.value.start
            ? dayjs(this.form.date.value.start).format("YYYY-MM-DD HH:mm:ss")
            : null;
          fields.end_date = this.form.date.value.start
            ? dayjs(this.form.date.value.end).format("YYYY-MM-DD HH:mm:ss")
            : null;
        }

        let url = "/tasks/api/task/create";
        if (this.data && this.data.id) {
          url = "/tasks/api/task/update";
          body.id = this.data.id;
          body.fields = fields;
        } else {
          body = fields;
        }
        this.$platrum.query(this, url, body, "post").then(({ data }) => {
          if (data) {
            this.resetCache().then(() => {
              let d = data;
              d.responsible_users = this.$store.state.users.filter((u) =>
                d.responsible_user_ids.includes(u.user_id)
              );
              let index = this.$store.state.tasks.findIndex((t) => t.id === data.id);

              if (index >= 0) {
                this.$store.state.tasks.splice(index, 1, d);
              } else {
                this.$store.state.tasks.push(d);
              }
              this.$store.state.task.open = false;
              this.$store.state.task.data = null;
              this.$nextTick(() => {
                this.$store.state.task.open = true;
                this.$store.state.task.data = d;
              });
            });
          }
        });
      }
    },
    deleteTask() {
      if (confirm("Вы уверены что хотите УДАЛИТЬ задачу?") && !this.removing) {
        this.removing = true;
        this.$platrum
          .query(
            this,
            "/tasks/api/task/remove",
            {
              author_id: this.$store.state.user.platrum_id,
              id: this.data.id,
            },
            "post"
          )
          .then(() => {
            this.resetCache().then(() => {
              this.removing = false;
              let index = this.$store.state.tasks.findIndex((t) => t.id === this.data.id);
              this.$store.state.tasks.splice(index, 1);
              this.$store.state.task.open = false;
              this.$store.state.task.data = null;
            });
          });
      }
    },
    resetCache() {
      let url = "/tasks/api/board/task/list?board_id=" + parseInt(this.$route.params.id);
      return fetch(`/resetcache/${encodeURIComponent(url)}`).finally(() => {
        this.boardLoading = false;
      });
    },
    closeTask() {
      this.$store.state.task.open = false;
      this.$store.state.task.data = null;
    },
  },
  components: {
    LoadingIndicator,
    RichTextInputComponent,
    DatePicker: () => import("v-calendar/lib/components/date-picker.umd"),
    SelectComponent,
    InputComponent,
    CheckboxComponent,
    IconComponent,
  },
};
</script>

<style lang="stylus">
.task-view {
  position absolute
  right -100%
  top 0
  height 100%
  width 100%
  background var(--white)
  max-width 680px
  box-shadow 0 15px 30px rgba(0, 0, 0, 0.15)
  transition var(--transition)
  display flex
  flex-direction column
  justify-content space-between
  gap 10px
  padding 16px
  overflow auto

  &__comments {
    display flex
    flex-direction column
    align-items center
    gap 16px
    position sticky
    bottom -16px
    z-index 3
    background var(--white)
    box-shadow 0 0 0 1px var(--gray-100)
    height 0
    margin: 0;
    padding 0

    &--visible {
      height 400px
      padding: 16px;
      margin: -16px -16px -5px;
    }

    .rich-text {
      width 100%

      &__body {
        min-height 50px
      }
    }
  }

  &__comment {
    display flex
    align-items flex-start
    justify-content flex-start
    gap 6px
    overflow hidden

    ^[0]__comments--visible & {
      overflow initial
    }

    img {
      width 20px
      height 20px
      border-radius 100%
      object-fit cover
      object-position c
      background var(--dark)
    }

    > div {
      display grid
      gap 4px

      span {
        font-size 0.875em
        color var(--dark-o5)
      }
    }
  }

  &__comments-toggle {
    absolute top 0
    transform translateY(-100%)
    background var(--white)
    width 40px
    border-radius var(--radius) var(--radius) 0 0
    box-shadow 0 0 0 1px var(--gray-100)
    display inline-flex
    align-items center
    justify-content center
    clip-path: inset(-1px -1px 0 -1px);
    cursor pointer

    .icon {
      width 24px
      height 24px
    }

    ^[0]__comments--visible & {
      .icon {
        transform rotate(180deg)
      }
    }
  }

  &__comments-form {
    display flex
    flex-direction column
    align-items flex-end
    gap 6px
    width 100%
    overflow hidden

    ^[0]__comments--visible & {
      overflow initial
    }
  }

  &__comments-list {
    display flex
    flex-direction column
    gap 6px
    overflow: hidden;
    margin: 0;
    width 100%
    height 100%
    padding: 0;

    ^[0]__comments--visible & {
      overflow: auto;
      margin: -16px 0;
      padding: 16px 0;
    }
  }

  &__section {
    display grid
    grid-gap 5px

    &-title {
      font-weight: 700;
      font-size: 0.865rem;
      line-height: 24px;
      color: var(--dark);
    }

    &-body {
      display grid
      gap 10px
    }
  }

  &--visible {
    right 0
  }

  &__check-list {
    display grid
    gap 6px

    .checkbox__field {
      align-items center
      width 100%
    }
  }

  &__check-list-input {
    background: var(--white);
    border none
    border-bottom: 1px solid var(--gray-900);
    display: flex;
    align-items: center;
    justify-content: center;
    height 20px
    padding 0 6px
    font-size 0.875rem
    line-height 20px
    flex-shrink: 0;
    appearance none
    outline none
    resize vertical
    flex-grow: 1;

    &--disabled & {
      opacity 0.7
      cursor not-allowed
    }
  }

  &__check-list-remove {
    background none
    border none
    cursor pointer
    padding 6px
    display inline-flex

    &:hover {
      background var(--gray-100)
      border-radius var(--radius)
    }

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--dark-o5)
      }
    }
  }

  &__header {
    display flex
    justify-content space-between
    align-items center
    position sticky
    top -16px
    z-index 3
    background var(--white)
    margin: -16px -16px -5px;
    padding: 16px;
    box-shadow 0 0 0 1px var(--gray-100)
  }

  &__header-buttons {
    display flex
    align-items center
    gap 12px
  }

  &__close {
    background none
    border none
    cursor pointer
    padding 6px
    display inline-flex
    background var(--gray-100)
    border-radius var(--radius)

    &:hover {
      background var(--gray-500)
    }

    .icon {
      width 24px
      height 24px

      svg path {
        fill var(--dark)
      }
    }
  }

  &__footer {
    display flex
    align-items center
    justify-content flex-end
    gap 16px
  }

  &__points {
    display grid
    grid-template-columns 1fr 1fr
    gap 16px
  }
}
</style>
