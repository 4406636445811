<template>
  <main class="board-page">
    <HomeAsideComponent />
    <HomeBoardComponent />
    <TaskViewComponent v-if="$store.state.task.open" />
  </main>
</template>

<script>
import HomeAsideComponent from "./components/HomeAsideComponent.vue";
import HomeBoardComponent from "./components/HomeBoardComponent.vue";
import TaskViewComponent from "./components/TaskViewComponent.vue";
import { getBoards } from "@/utils.js";

export default {
  name: "BoardPage",
  async asyncData(app, context, reject) {
    let boardPromise = getBoards(app).then(() => {
      app.$store.state.board = app.$store.state.boards.find((b) => b.id === parseInt(app.$route.params.id));
      if (!app.$store.state.board) {
        reject({ code: 413 });
      }
    });

    let tasksGetPromise = app.$platrum.get("/user/api/profile/list").then(async (json) => {
      let users = [];
      Object.keys(json).forEach((k) => users.push(json[k]));
      let url = "/tasks/api/board/task/list?board_id=" + parseInt(app.$route.params.id);
      await app.$platrum.cache(app, url);
      await app.$platrum.get(url).then((data) => {
        app.$store.state.tasks = data.map((d) => {
          d.responsible_users = users.filter((u) => d.responsible_user_ids.includes(u.user_id));
          return d;
        });
      });
    });
    await Promise.all([boardPromise, tasksGetPromise]);
  },
  components: { TaskViewComponent, HomeBoardComponent, HomeAsideComponent },
};
</script>

<style lang="stylus">
@import "~dragula/dist/dragula.min.css"
.board-page {
  display flex
  align-items flex-start
  justify-content flex-start
  width 100%
  overflow hidden
  height calc(100vh - 70px)
  position relative
}
</style>
