<template>
  <div class="board" :class="{ 'board--loading': boardLoading }" ref="board">
    <div class="board__column board__column--new" data-status="new">
      <div class="board__column-header">
        <span class="board__column-title">Новые</span>
        <button type="button" class="board__column-add" title="Добавить задачу" @click="addTask">
          <IconComponent name="add" />
        </button>
      </div>
      <TaskCardComponent v-for="(task, i) in newTasks" :key="i" :data="task" />
    </div>
    <div class="board__column board__column--in-progress" data-status="in_progress">
      <div class="board__column-header">
        <span class="board__column-title">В работе</span>
      </div>
      <TaskCardComponent v-for="(task, i) in progressTasks" :key="i" :data="task" />
    </div>
    <div class="board__column board__column--waiting" data-status="review">
      <div class="board__column-header">
        <span class="board__column-title">Ждут проверки</span>
      </div>
      <TaskCardComponent v-for="(task, i) in reviewTasks" :key="i" :data="task" />
    </div>
    <div class="board__column board__column--finished" data-status="finished">
      <div class="board__column-header">
        <span class="board__column-title">Завершенные</span>
      </div>
      <TaskCardComponent v-for="(task, i) in finishedTasks" :key="i" :data="task" />
    </div>
    <div class="board__column board__column--postponed" data-status="draft">
      <div class="board__column-header">
        <span class="board__column-title">Отложенные/Информация</span>
      </div>
      <TaskCardComponent v-for="(task, i) in draftTasks" :key="i" :data="task" />
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import TaskCardComponent from "./TaskCardComponent.vue";

export default {
  name: "HomeBoardComponent",
  data: () => ({
    boardLoading: false,
    dragula: null,
    board: null,
  }),
  computed: {
    draftTasks() {
      return this.$store.state.tasks.filter((t) => t.status_key === "draft");
    },
    finishedTasks() {
      return this.$store.state.tasks.filter((t) => t.status_key === "finished");
    },
    reviewTasks() {
      return this.$store.state.tasks.filter((t) => t.status_key === "review");
    },
    progressTasks() {
      return this.$store.state.tasks.filter((t) => t.status_key === "in_progress");
    },
    newTasks() {
      return this.$store.state.tasks.filter((t) => t.status_key === "new");
    },
    tasks() {
      return this.$store.state.tasks;
    },
  },
  mounted() {
    if (this.$store.state.user.platrum_id) {
      require(["dragula"], (dragula) => {
        this.dragula = dragula;
        this.initBoard();
      });
    }
  },
  methods: {
    addTask() {
      this.$store.state.task.open = false;
      this.$store.state.task.data = null;
      this.$nextTick(() => {
        this.$store.state.task.data = this.data;
        this.$store.state.task.open = true;
      });
    },
    initBoard() {
      this.board = this.dragula(Array.from(this.$refs.board.querySelectorAll(".board__column")), {
        moves: (el) => {
          return !this.boardLoading && !el.classList.contains("board__column-header");
        },
      });
      this.board.on("drop", (el, target) => {
        this.boardLoading = true;
        this.$platrum
          .query(
            this,
            "/tasks/api/task/update",
            {
              id: parseInt(el.dataset.id),
              fields: { status_key: target.dataset.status },
            },
            "post"
          )
          .then(async () => {
            let url = "/tasks/api/board/task/list?board_id=" + parseInt(this.$route.params.id);
            await fetch(`/resetcache/${encodeURIComponent(url)}`).finally(() => {
              this.boardLoading = false;
            });
          })
          .catch(() => {
            this.boardLoading = false;
          });
      });
    },
  },
  components: { TaskCardComponent, IconComponent },
};
</script>

<style lang="stylus">
.board {
  padding 16px
  width 100%
  display flex
  align-items flex-start
  height 100%
  gap 24px
  overflow auto
  scrollbar-width thin
  scrollbar-color rgba(0, 0, 0, 0.3) transparent
  transition var(--transition)

  &::-webkit-scrollbar {
    width 8px
    height 8px
  }

  &::-webkit-scrollbar-track {
    background transparent
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color rgba(0, 0, 0, 0.3)
    border-radius 3px
    border none
  }

  &--loading {
    opacity .3
  }

  &__column {
    display flex
    flex-direction column
    grid-gap 10px
    border-radius var(--radius)
    width 300px
    background var(--gray)
    min-height 300px
    padding 4px 12px 10px
    box-shadow 0 0 0 1px var(--gray-100)
    flex-shrink 0

    &--in-progress .task {
      opacity 0.9
    }

    &--waiting .task:not(.task--urgent) {
      box-shadow 0 0 0 1px var(--blue-500)
      border-bottom 2px solid var(--blue-500)
    }

    &--finished .task {
      opacity 0.6

      &__title {
        text-decoration line-through
      }
    }

    &--postponed .task {
      opacity 0.8
    }
  }

  &__column-header {
    display flex
    align-items center
    justify-content space-between
    height 36px
  }

  &__column-add {
    background none
    border none
    display inline-flex
    align-items center
    padding 6px
    cursor pointer

    &:hover {
      background var(--gray-100)
      border-radius var(--radius)
    }

    .icon {
      width 24px
      height 24px

      svg path {
        fill var(--dark)
      }
    }
  }

  &__column-title {
    font-size 1.375rem
    color var(--dark)
  }
}
</style>
