<template>
  <div
    v-click-outside="close"
    class="select"
    :class="{
      'select--values-visible': !focused && multiple && value && value.length,
      'select--focused': focused,
      'select--error': errors.length,
      'select--disabled': disabled,
      'select--multiple': multiple,
      'select--list-top': listPosition === 'top',
      'select--list-bottom': listPosition === 'bottom',
    }"
  >
    <label class="select__title" :for="id" v-if="title">
      {{ title }}
      <span v-if="required">*</span>
    </label>
    <div class="select__input-container">
      <div class="select__field" ref="input">
        <label class="select__field-label" :for="id" aria-hidden="true"></label>
        <input
          :id="id"
          type="text"
          class="select__input"
          :value="inputValue"
          @input="query = $event.target.value"
          @focus="focus"
          :disabled="disabled"
          :placeholder="placeholder"
        />
        <div class="select__values" v-if="multiple">
          <label :for="id"></label>
          <button type="button" v-for="(value, i) in value" :key="i" @click="remove(i)">
            <span>{{ getOptionTitle(value) }}</span>
            <IconComponent name="close" />
          </button>
        </div>
        <button class="select__clear" type="button" @click="clear" v-if="canShowClear">
          <IconComponent name="close" />
        </button>
        <label :for="id" class="select__arrow">
          <IconComponent name="arrow-down-1" />
        </label>
      </div>
      <SelectListComponent :query="query" v-bind="$props" @input="listUpdate">
        <template v-slot="{ item, i }">
          <slot :i="i" :item="item"></slot>
        </template>
      </SelectListComponent>
    </div>
    <div class="select__errors" v-if="errors && errors.length">
      <span v-for="(error, i) in errors" :key="i">{{ error }}</span>
    </div>
    <div class="select__messages" v-if="messages && messages.length">
      <span v-for="(message, i) in messages" :key="i">{{ message }}</span>
    </div>
  </div>
</template>

<script>
import IconComponent from "@/components/IconComponent.vue";
import ClickOutside from "vue-click-outside";
import SelectListComponent from "@/components/inputs/select/list.vue";

export default {
  name: "SelectComponent",
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    labelName: {
      type: [String, Array],
      default: "title",
    },
    value: [Array, Object],
    messages: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    required: Boolean,
    multiple: Boolean,
    clearable: Boolean,
    disabled: Boolean,
  },
  data: () => {
    return {
      query: "",
      listPosition: null,
      focused: false,
    };
  },
  computed: {
    canShowClear() {
      if (this.clearable) {
        if (this.multiple) {
          return this.value ? this.value.length : false;
        }
        return this.value;
      }
      return false;
    },
    inputValue() {
      if (this.focused) {
        return this.query;
      }
      if (this.value) {
        return this.getOptionTitle(this.value);
      }
      return "";
    },
  },
  methods: {
    focus() {
      if (!this.disabled) {
        this.focused = true;
        this.openDrop();
      }
    },
    openDrop() {
      if (this.$refs.input) {
        const height = 200; // Минимум пространства
        const bottom = this.$refs.input.getBoundingClientRect().bottom;
        if (bottom + height > window.innerHeight) {
          this.listPosition = "top";
          return;
        }
        this.listPosition = "bottom";
      }
    },
    listUpdate($event) {
      this.$emit("input", $event);
      if (!this.multiple) {
        this.close();
      }
    },
    close() {
      this.focused = false;
      this.listPosition = null;
      this.query = "";
    },
    remove(i) {
      if (!this.disabled) {
        let array = this.value;
        array.splice(i, 1);
        this.$emit("input", array);
      }
    },
    clear() {
      this.$emit("input", this.multiple ? [] : null);
    },
    getOptionTitle(option) {
      if (Array.isArray(this.labelName)) {
        return option[this.labelName[0]][this.labelName[1]];
      }
      return option[this.labelName];
    },
  },
  components: { SelectListComponent, IconComponent },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus">
.select {
  display inline-grid
  grid-gap 5px
  position relative

  .select-list {
    position absolute
    height 0
    width 0
    z-index 10
    opacity 0
    visibility hidden
    transition opacity .2s

    // ^[0]--focused & {  = .select--focused .select .select-list ¯\_(ツ)_/¯

    ^[0]--focused& { // = .select--focused .select-list ¯\_(ツ)_/¯
      opacity 1
      visibility: visible;
      height auto
      width 100%
      max-height 200px
      left 0
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
      //box-shadow: 0 0 0 3px var(--main-o15);
    }

    ^[0]--list-bottom& {
      bottom 0
      border-top-right-radius 0
      border-top-left-radius 0
      transform translateY(100%)
    }

    ^[0]--list-top& {
      top 0
      border-bottom-right-radius 0
      border-bottom-left-radius 0
      transform translateY(-100%)
    }
  }

  &__title {
    font-weight: 700;
    font-size: 0.865rem;
    line-height: 24px;
    color: var(--dark);

    ^[0]--disabled & {
      opacity 0.7
      cursor not-allowed
    }

    ^[0]--error & {
      color var(--red)
    }

    > span {
      color: var(--red);
    }
  }

  &__clear {
    height 100%
    background none
    border none
    padding 0 15px
    margin-right -15px
    display inline-flex
    align-items center
    justify-content center
    z-index 1

    ^[0]--focused & {
      display none
    }

    .icon svg {
      width: 16px;
      height: 16px;
    }
  }

  &__field-label {
    position absolute
    left 0
    top 0
    width 100%
    height 100%
    cursor text
  }

  &__field {
    display flex
    background var(--white)
    border 1px solid var(--blue-100)
    border-radius var(--radius)
    height 50px
    transition background .2s, box-shadow .2s, border-color .2s
    align-items stretch
    justify-content stretch
    padding 1px 16px
    position relative
    padding-right 1px
    width 100%
    overflow hidden
    z-index: 2;

    ^[0]--disabled & * {
      opacity 0.7
      cursor not-allowed
    }

    ^[0]--values-visible & {
      background: var(--white);
    }

    ^[0]--focused & {
      padding 0 15px
      padding-right 0
      background: var(--white);
      z-index 11
    }
    focusBottom = '^[0]--list-bottom^[0]--focused &';

    {focusBottom} {
      border-bottom-left-radius 0
      border-bottom-right-radius 0
    }

    focusTop = '^[0]--list-top^[0]--focused &';

    {focusTop} {
      border-top-left-radius 0
      border-top-right-radius 0
    }

    errorAndFocus = '^[0]--error^[0]--focused &';

    {errorAndFocus} {
      box-shadow: 0 0 0 3px var(--red-o15);
    }

    ^[0]--error & {
      background: var(--white);
      border-color var(--red);
    }
  }

  &__input {
    appearance none
    border none
    background none
    outline none
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 20px;
    color: var(--dark);
    height 100%
    width 100%
    opacity 1
    overflow visible

    ^[0]--values-visible & {
      width 0
      height 0
      opacity 0
      overflow hidden
    }
  }

  &__input-container {
    position relative
    width 100%
  }

  &__arrow {
    height 100%
    padding 0 15px
    display inline-flex
    align-items center
    justify-content center
    z-index 1
    cursor pointer

    .icon svg {
      width: 10px;
      height: 10px;
      transition .2s

      ^[0]--focused & {
        transform rotate(180deg)
      }
    }
  }

  &__values {
    height 100%
    width 100%
    overflow hidden
    align-items center
    gap 5px
    display none
    position relative

    ^[0]--values-visible & {
      display flex
    }

    label {
      position absolute
      left 0
      top 0
      width 100%
      bottom 0
      z-index 1
      cursor text
    }

    button {
      z-index 2
      background: var(--gray-100);
      border: 1px solid var(--gray-dark);
      border-radius: var(--radius);
      display inline-flex
      align-items center
      gap 5px
      padding 2px 5px
      font-weight: 500;
      font-size: 0.75rem;
      white-space nowrap
      line-height: 20px;
      color: var(--dark);

      .icon {
        width 12px
        height 12px

        svg path {
          fill var(--dark)
        }
      }
    }
  }

  &__errors,
  &__messages {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 15px;
    color: var(--dark);
    opacity: 0.4;
    display grid
    grid-gap 3px
  }

  &__errors {
    opacity 1
    color var(--red)
  }
}
</style>
