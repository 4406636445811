<template>
  <div
    class="checkbox"
    :class="{
      'checkbox--error': errors.length,
      'checkbox--focused': focused,
      'checkbox--active': value || indeterminate,
      'checkbox--disabled': disabled,
    }"
  >
    <label class="checkbox__field" @click.shift="$emit('shift:input', value)">
      <span class="checkbox__icon">
        <IconComponent name="minus" v-if="indeterminate" />
        <IconComponent name="tick" v-else-if="value" />
      </span>
      <input
        :disabled="disabled"
        @focus="focused = true"
        @blur="focused = false"
        @input="$emit('input', indeterminate ? false : $event.target.checked)"
        type="checkbox"
        :value="value"
      />
      <slot></slot>
    </label>
    <div class="checkbox__errors" v-if="errors.length">
      <span v-for="(error, i) in errors" :key="i">{{ error }}</span>
    </div>
    <div class="checkbox__messages" v-if="messages.length">
      <span v-for="(message, i) in messages" :key="i">{{ message }}</span>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "CheckboxComponent",
  components: { IconComponent },
  props: {
    value: Boolean,
    disabled: Boolean,
    indeterminate: Boolean,
    messages: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    focused: false,
  }),
};
</script>

<style lang="stylus">
.checkbox {
  display inline-flex
  gap 5px
  position relative

  &--disabled {
    opacity 0.7
    cursor not-allowed
  }

  &__icon {
    background: var(--white);
    border: 1px solid var(--gray-900);
    border-radius: 3px;
    display flex
    align-items center
    justify-content center
    width 18px
    height 18px
    transition .2s
    flex-shrink 0

    ^[0]--active & {
      background: var(--blue-500);
      border-color var(--blue-500)
    }

    ^[0]--focused & {
      border-color var(--dark);
    }

    .icon {
      width 100%
      height 100%
      margin -1px

      svg path {
        fill var(--white)
      }
    }
  }

  &__field {
    display flex
    align-items flex-start
    justify-content flex-start
    gap 5px
    cursor pointer

    input[type="checkbox"] {
      appearance none
      position absolute
      left 0
      top 0
      border none
      background none
      outline none
      overflow hidden
      height 0
      width 0
    }
  }

  &__errors,
  &__messages {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 15px;
    color: var(--dark);
    opacity: 0.4;
    display grid
    grid-gap 3px
  }

  &__errors {
    opacity 1
    color var(--red)
  }
}
</style>
