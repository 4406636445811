<template>
  <button
    type="button"
    class="task"
    :class="{ 'task--red': shouldGetAttention }"
    :data-id="data.id"
    @click="openTask"
  >
    <span class="task__title" v-if="data.name">{{ data.name }}</span>
    <time class="task__date" title="Дата начала и окончания задачи" v-if="data.start_date">
      <IconComponent name="calendar-1" />
      <span>{{ data.start_date | humanDate }} - {{ data.finish_date | humanDate }}</span>
    </time>
    <span class="task__responsible-list" v-if="data.responsible_users.length">
      <span class="task__responsible" v-for="(user, i) in data.responsible_users" :key="i">
        <img class="task__responsible-img" :src="user.avatar | img(100, 100)" :alt="user.user_name" />
        <span class="task__responsible-name">{{ user.user_name }}</span>
      </span>
    </span>
    <span class="task__points" v-if="canShowPointsFooter">
      <span title="Story points" v-if="data.time_plan">
        <IconComponent name="weight" />
        <span>{{ data.time_plan }}</span>
      </span>
      <span title="Время (ч)" v-if="data.time_fact">
        <IconComponent name="timer-1" />
        <span>{{ data.time_fact }} ч</span>
      </span>
      <span title="Комментарии" v-if="data.comment_count">
        <IconComponent name="messages-2" />
        <span>{{ data.comment_count }}</span>
      </span>
      <span title="Задач выполнено" v-if="data.checklist_data && data.checklist_data.total_count">
        <IconComponent name="tick-circle" />
        <span>{{ data.checklist_data.done_count }}/{{ data.checklist_data.total_count }}</span>
      </span>
    </span>
  </button>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import dayjs from "dayjs";

export default {
  name: "TaskCardComponent",
  props: {
    data: Object,
  },
  computed: {
    canShowPointsFooter() {
      return [
        this.data.time_plan,
        this.data.time_fact,
        this.data.comment_count,
        this.data.checklist_data.total_count,
      ].filter((i) => !!i).length;
    },
    shouldGetAttention() {
      return (
        this.data.is_important ||
        (this.data.finish_date &&
          dayjs().diff(dayjs(this.data.finish_date)) >= 0 &&
          this.data.status_key !== "finished")
      );
    },
  },
  methods: {
    openTask() {
      this.$store.state.task.open = false;
      this.$store.state.task.data = null;
      this.$nextTick(() => {
        this.$store.state.task.data = this.data;
        this.$store.state.task.open = true;
      });
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.task {
  padding 8px 10px
  box-shadow 0 0 0 1px var(--gray-100)
  border none
  text-align left
  text-decoration none
  background var(--white)
  cursor pointer
  width 100%
  border-radius var(--radius)
  display flex
  flex-direction column
  gap 8px
  transition var(--transition)
  color var(--dark)

  &--red {
    box-shadow 0 0 0 1px var(--red)
    border-bottom 2px solid var(--red)
  }

  &:hover {
    box-shadow 0 0 0 1px var(--gray-900)
  }

  &__title {
    font-weight 500
    line-height 21px
    color var(--dark)
  }

  &__responsible-list {
    display flex
    flex-wrap wrap
    gap 8px
  }

  &__responsible,
  &__points,
  &__date {
    font-size 0.875rem
    display flex
    align-items center
    gap 4px
    color var(--dark-o5)

    span {
      line-height 24px
    }
  }

  &__responsible-img {
    width 20px
    height 20px
    object-fit cover
    object-position center
    border-radius 100%
    background var(--dark)
    overflow hidden
  }

  &__points {
    gap 16px
    width 100%
    justify-content flex-start

    span {
      gap 4px
      align-items center
      display flex
    }
  }

  .icon {
    width 20px
    height 20px

    svg path {
      fill var(--dark-o5)
    }
  }
}
</style>
