var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"select",class:{
    'select--values-visible': !_vm.focused && _vm.multiple && _vm.value && _vm.value.length,
    'select--focused': _vm.focused,
    'select--error': _vm.errors.length,
    'select--disabled': _vm.disabled,
    'select--multiple': _vm.multiple,
    'select--list-top': _vm.listPosition === 'top',
    'select--list-bottom': _vm.listPosition === 'bottom',
  }},[(_vm.title)?_c('label',{staticClass:"select__title",attrs:{"for":_vm.id}},[_vm._v("\n    "+_vm._s(_vm.title)+"\n    "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"select__input-container"},[_c('div',{ref:"input",staticClass:"select__field"},[_c('label',{staticClass:"select__field-label",attrs:{"for":_vm.id,"aria-hidden":"true"}}),_c('input',{staticClass:"select__input",attrs:{"id":_vm.id,"type":"text","disabled":_vm.disabled,"placeholder":_vm.placeholder},domProps:{"value":_vm.inputValue},on:{"input":function($event){_vm.query = $event.target.value},"focus":_vm.focus}}),(_vm.multiple)?_c('div',{staticClass:"select__values"},[_c('label',{attrs:{"for":_vm.id}}),_vm._l((_vm.value),function(value,i){return _c('button',{key:i,attrs:{"type":"button"},on:{"click":function($event){return _vm.remove(i)}}},[_c('span',[_vm._v(_vm._s(_vm.getOptionTitle(value)))]),_c('IconComponent',{attrs:{"name":"close"}})],1)})],2):_vm._e(),(_vm.canShowClear)?_c('button',{staticClass:"select__clear",attrs:{"type":"button"},on:{"click":_vm.clear}},[_c('IconComponent',{attrs:{"name":"close"}})],1):_vm._e(),_c('label',{staticClass:"select__arrow",attrs:{"for":_vm.id}},[_c('IconComponent',{attrs:{"name":"arrow-down-1"}})],1)]),_c('SelectListComponent',_vm._b({attrs:{"query":_vm.query},on:{"input":_vm.listUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ item, i }){return [_vm._t("default",null,{"i":i,"item":item})]}}],null,true)},'SelectListComponent',_vm.$props,false))],1),(_vm.errors && _vm.errors.length)?_c('div',{staticClass:"select__errors"},_vm._l((_vm.errors),function(error,i){return _c('span',{key:i},[_vm._v(_vm._s(error))])}),0):_vm._e(),(_vm.messages && _vm.messages.length)?_c('div',{staticClass:"select__messages"},_vm._l((_vm.messages),function(message,i){return _c('span',{key:i},[_vm._v(_vm._s(message))])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }