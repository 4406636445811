<template>
  <div class="aside" :class="{ 'aside--open': $store.state.asideOpen && boards.length > 1 }">
    <!--    <div class="aside__header">-->
    <!--      <a-->
    <!--        class="aside__tab"-->
    <!--        :class="{ 'aside__tab&#45;&#45;active': !haveFavoriteFilter }"-->
    <!--        :href="route({ name: 'board', params: $route.params })"-->
    <!--      >-->
    <!--        Все проекты-->
    <!--      </a>-->
    <!--      <a-->
    <!--        v-if="$store.state.user.platrum_id"-->
    <!--        class="aside__tab"-->
    <!--        :class="{ 'aside__tab&#45;&#45;active': haveFavoriteFilter }"-->
    <!--        :href="route({ name: 'board', params: $route.params, query: { favorite: 1 } })"-->
    <!--      >-->
    <!--        Избранное-->
    <!--      </a>-->
    <!--    </div>-->
    <div class="aside__links">
      <a
        class="aside__link"
        :class="{ 'aside__link--active': board.id === parseInt($route.params.id || '0') }"
        :href="route({ name: 'board', params: { id: board.id } })"
        v-for="(board, i) in boards"
        :key="i"
      >
        {{ board.name }}
      </a>
    </div>
  </div>
</template>

<script>
import {route} from "@/utils.js";

export default {
  name: "HomeAsideComponent",
  methods: { route },
  computed: {
    haveFavoriteFilter() {
      let favorite = this.$route.query.favorite;
      return favorite && favorite === "1";
    },
    boards() {
      return this.$store.state.boards;
    },
  },
};
</script>

<style lang="stylus">
.aside {
  display flex
  flex-direction column
  gap 18px
  padding 16px 0
  background var(--blue-100)
  height 100%
  width 0
  opacity 0
  transition var(--transition)
  overflow hidden
  overflow-y auto
  scrollbar-width thin
  scrollbar-color rgba(0, 0, 0, 0.3) transparent

  &::-webkit-scrollbar {
    width 8px
    height 8px
  }

  &::-webkit-scrollbar-track {
    background transparent
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color rgba(0, 0, 0, 0.3)
    border-radius 3px
    border none
  }

  &--open {
    opacity 1
    width 300px
    padding 16px
  }

  &__header {
    display flex
    align-items center
    margin 0 -8px
    flex-wrap wrap
  }

  &__tab {
    font-weight 600
    font-size 0.75rem
    color var(--white)
    opacity 0.5
    cursor pointer
    padding 4px 8px

    &:hover {
      background var(--blue-500)
      border-radius var(--radius)
    }

    &--active {
      opacity 1
    }
  }

  &__links {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 10px
  }

  &__link {
    color var(--white)
    text-decoration none
    opacity 0.8
    font-weight 500

    &--active {
      opacity 1
    }
  }
}
</style>
